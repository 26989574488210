import React from "react";
import Title from "../../components/Title";
import { ContainerHome } from "./style";

export default function Home() {
  return (
    <ContainerHome>
      <Title name="Olá! Seja bem-vindo (a) ao Ligando Ideias Redação!" />

      <p>
        Nós somos a equipe Ligando Ideias Redação, e é um prazer imenso tê-lo
        (a) aqui conosco! Eu sou a Lays, e, junto com toda a nossa equipe,
        estaremos ao seu lado nessa jornada para aperfeiçoar sua produção
        textual para o Enem e os vestibulares. Nosso objetivo é proporcionar um
        espaço completo e acolhedor para o seu desenvolvimento, oferecendo
        orientações valiosas sobre todos os aspectos da redação, desde as
        atualidades até as técnicas estruturais essenciais. Aqui, você terá
        acesso ao nosso curso de redação on-line, com conteúdos exclusivos que
        vão te ajudar a dominar cada detalhe dos gêneros textuais. Além disso,
        você poderá enviar suas redações para correção, e o melhor: nossa
        correção será super detalhada, com a nota e um parecer completo de nossa
        equipe, garantindo que você saiba exatamente o que pode melhorar.
        Incrível, né? Temos certeza de que, ao longo desse processo, você não
        apenas se tornará um(a) excelente candidato(a) para a vaga dos seus
        sonhos, mas também desenvolverá um senso crítico apurado, essencial para
        a sua formação como cidadão (ã).{" "}
        <i>Nossa missão é transformar REDAÇÃO em APROVAÇÃO</i>, e estamos aqui
        para ajudar você a conquistar esse objetivo! Então, está preparado(a)
        para dar os primeiros passos rumo ao seu sucesso? Vamos juntos! Um beijo
        virtual e bons estudos!
      </p>
    </ContainerHome>
  );
}
